<template>
	<div>
		<div class="simple-title">Customer Sign Up<span class="fal fa-user"></span></div>
		<div class="form-wrapper small">
			<div class="tabs"><router-link to="/signup/customer">Customer<span class="fal fa-user"></span></router-link><router-link to="/signup/business">Business<span class="fal fa-store"></span></router-link></div>
			<div class="form-container">
				<a href @click.prevent="() => loginDriver('customer', 'google')" class="google-button"><span class="fab fa-google"></span>Sign up with Google</a>
				<a v-if="showAppleSignIn" href @click.prevent="() => loginDriver('customer', 'apple')" class="apple-button"><span class="fab fa-apple"></span>Sign up with Apple</a>
				<div class="oauth-buffer"></div>

				<div class="form-title">Sign Up with Email</div>

				<form action @submit.prevent="onSubmitSignup">

				<form-errors :errors="errors"></form-errors>

				<field-errors v-if="submitAttempted" :form="$v.form" field="first_name"></field-errors>
				<div class="input-wrapper">
					<div class="input-title subtle-shrink">First Name</div>
					<div class="input-box"><input type="text" v-model="form.first_name"></div>
				</div>
				<field-errors v-if="submitAttempted" :form="$v.form" field="last_name"></field-errors>
				<div class="input-wrapper">
					<div class="input-title subtle-shrink">Last Name</div>
					<div class="input-box"><input type="text" v-model="form.last_name"></div>
				</div>
				<field-errors v-if="submitAttempted" :form="$v.form" field="email"></field-errors>
				<div class="input-wrapper">
					<div class="input-title subtle-shrink">Email Address</div>
					<div class="input-box"><input type="email" v-model="form.email" placeholder="johnny@appleseed.com" autocomplete="email"></div>
				</div>
				<field-errors v-if="submitAttempted" :form="$v.form" field="password"></field-errors>
				<div class="input-wrapper">
					<div class="input-title subtle-shrink">Password</div>
					<div class="input-box"><input type="password" v-model="form.password" placeholder="**************" autocomplete="new-password"></div>
				</div>
				<field-errors v-if="submitAttempted" :form="$v.form" field="password_confirmation"></field-errors>
				<div class="input-wrapper">
					<div class="input-title subtle-shrink">Confirm Password</div>
					<div class="input-box"><input type="password" v-model="form.password_confirmation" placeholder="**************" autocomplete="new-password"></div>
				</div>
				<submit-button text="Sign Up" icon="fa-pencil" :loading="formSaving" :has-errors="hasErrors"></submit-button>

				</form>
			</div>
		</div>
	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import { required, email, sameAs } from 'vuelidate/lib/validators';
import FormMixin from '@/mixins/FormMixin';
import SocialAuthMixin from '@/mixins/SocialAuthMixin';


export default {

	name: 'CustomerSignUpPage',

	mixins: [ FormMixin, SocialAuthMixin ],

	data() {
		return {
			form: {},
		};
	},

	validations: {
		form: {
			first_name: { required },
			last_name: { required },
			email: { required, email },
			password: { required },
			password_confirmation: {
				required,
				sameAs: sameAs('password')
			},
		},
	},

	computed: {

		...mapGetters([ 'currentLocation', 'platform' ]),

		showAppleSignIn() {
			return this.platform === 'ios' || this.platform === 'web';
		},

	},

	methods: {

		async onSubmitSignup() {
			this.form.latitude = this.currentLocation.latitude;
			this.form.longitude = this.currentLocation.longitude;

			const formData = {
				...this.form,
				platform: this.platform
			};

			this.submit(async () => {
				await this.$api.post('/signup', formData);

				this.$store.dispatch('addToast', 'Welcome to Fine Grounds!');

				this.$store.dispatch('goIntended', '/browse/list');
			});
		},
	}

};

</script>
