import { mapGetters } from 'vuex';
if (process.client) {
	require('@codetrix-studio/capacitor-google-auth');
}
import { Plugins } from '@capacitor/core';

export default {

	computed: mapGetters(['platform']),

	methods: {

		loginDriver(userType, driver) {
			if (this.platform === 'web' || !this.platform) {
				return this.webDriver(userType, driver);
			} else {
				return this.mobileDriver(userType, driver);
			}
		},

		async webDriver(userType, driver) {
			try {
				const response = await this.$api.get(`/login/${ userType }/${ driver }/url`);

				window.location.href = response.data;
			} catch(e) {}
		},

		mobileDriver(userType, driver) {
			switch (driver) {
				case 'google':
					return this.googleMobileDriver(userType);
				case 'apple':
					if (this.platform === 'ios') {
						return this.iosAppleMobileDriver(userType);
					}
			}
		},

		async googleMobileDriver(userType) {
			const params = {};
			try {
				const response = await Plugins.GoogleAuth.signIn();
				if (this.platform === 'android') {
					params.code = response.serverAuthCode;
				} else {
					params.access_token = response.authentication.accessToken;
				}
				this.authenticateMobileDriver(userType, 'google', params);
			} catch (e) {
				this.error = e.message;
				this.$rollbar.error('Google Sign In Failed', { e, params});
			}
		},

		async iosAppleMobileDriver(userType) {
			let params;
			try {
				const { response } = await Plugins.SignInWithApple.authorize({
					clientId: 'app.finegrounds.app',
					scopes: 'email name',
				});

				params = {
					code: response.authorizationCode,
					id_token: response.identityToken,
					userId: response.user,
					givenName: response.givenName,
					familyName: response.familyName,
					email: response.email
				};

				this.handleAuthenticateMobileDriverResponse(await this.$api.get('/login/app/apple/callback', { params }), userType);
			} catch (e) {
				this.$rollbar.warn('Apple Sign In Failed: ' + e.message, { e, params });
				this.$store.dispatch('errorToast', 'Something went wrong logging you in');
			}
		},

		async authenticateMobileDriver(userType, driver, params) {
			let url = `/login/${ userType }/${ driver }/callback/token`;

			try {
				const response = await this.$api.get(url, { params });

				this.handleAuthenticateMobileDriverResponse(response, userType);

			} catch (e) {
				this.$rollbar.warn('Failed to auth via mobile driver', { e, params });
				this.$store.dispatch('errorToast', 'Something went wrong logging you in');
			}

			return false;
		},

		handleAuthenticateMobileDriverResponse(response, userType) {
			const authHeader = response.headers['x-user'];

			if (userType === 'business' && authHeader) {
				const auth = JSON.parse(atob(authHeader));
				if (auth.new) {
					// This is a new account. Let's go to the welcome page that'll also ensure we load all their locations & items
					this.$nextTick(() => {
						this.$router.push('/business/welcome');
					});
					return;
				}

			}

			this.$nextTick(() => {
				this.$store.dispatch('goIntended', userType === 'customer' ? '/browse/list' : '/business/locations');
			});
		},
	},
};
