<template>
	<div>
		<div class="simple-title">Customer Log In<span class="fal fa-user"></span></div>
		<div class="form-wrapper small">
			<div class="tabs"><router-link to="/login/customer">Customer<span class="fal fa-user"></span></router-link><router-link to="/login/business">Business<span class="fal fa-store"></span></router-link></div>
			<div class="form-container">
				<a @click.prevent="() => loginDriver('customer', 'google')" class="google-button"><span class="fab fa-google"></span>Sign in with Google</a>
				<a v-if="showAppleSignIn" href @click.prevent="() => loginDriver('customer', 'apple')" class="apple-button"><span class="fab fa-apple"></span>Sign in with Apple</a>
				<div class="oauth-buffer"></div>

				<form-errors :errors="errors"></form-errors>

				<div class="form-title">Sign in with Email</div>

				<form action @submit.prevent="attemptTraditionalLogin">

				<field-errors v-if="submitAttempted" :form="$v.form" field="email"></field-errors>
				<div class="input-wrapper">
					<div class="input-title">Email Address</div>
					<div class="input-box"><input type="text" v-model="form.email" autocomplete="email"></div>
				</div>

				<field-errors v-if="submitAttempted" :form="$v.form" field="password"></field-errors>
				<div class="input-wrapper">
					<div class="input-title">Password</div>
					<div class="input-box"><input type="password" v-model="form.password" autocomplete="current-password"></div>
				</div>

				<submit-button text="Log In" icon="fa-key" :loading="formSaving" :has-errors="hasErrors"></submit-button>

				</form>

				<div class="form-link">Forgot your password? <router-link to="/forgot-password">Click here</router-link></div>
			</div>
		</div>
	</div>
</template>

<script>

import { required, email } from 'vuelidate/lib/validators';
import FormMixin from '@/mixins/FormMixin';
import SocialAuthMixin from '@/mixins/SocialAuthMixin';
import { mapGetters } from 'vuex';
import { hasCompletedSignup } from '@/utils/verify-signup-status';

export default {

	name: 'CustomerLoginPage',

	mixins: [ FormMixin, SocialAuthMixin ],

	data() {
		return {
			form: {},
			clearIntendedOnDestroy: true,
		};
	},

	computed: {

		...mapGetters(['isLoggedIn', 'platform' ]),

		showAppleSignIn() {
			return this.platform === 'ios' || this.platform === 'web';
		},

	},

	validations: {
		form: {
			email: {
				required,
				email,
			},
			password: { required },
		},
	},

	beforeDestroy() {
		if (this.clearIntendedOnDestroy) {
			this.$store.dispatch('clearIntended');
		}
	},

	methods: {

		async attemptTraditionalLogin() {
			await this.submit(async () => {
				try {
					await this.$api.get('/sanctum/csrf-cookie');
				} catch(e) {
					this.$store.dispatch('errorToast', 'Unexpected error encountered');
				}

				const formData = {
					...this.form,
					platform: this.platform
				};

				await this.$api.post('/login', formData);

				this.$nextTick(() => {
					if (!hasCompletedSignup(this.$store)) {
						this.clearIntendedOnDestroy = false;
						return this.$router.push('/signup/customer/info');
					}
					this.$store.dispatch('goIntended');
				});
			});
		},

	}

};

</script>
